<template>
    <span class="imgItem" style="width: auto;height: auto;" @mouseover="mouseoverFun" @mouseleave="mouseleaveFun" >
        <slot></slot>
    </span>
</template>
  
<script>
  export default {
    name: 'imgItem',
    props: {},

    data(){

        return {
            
        }
    },

    created(){
    },

    methods:{
		mouseoverFun(e){

            var mouseenterOffLeftRight = false;

            var wPicShow = parseFloat(window.innerWidth);
            var xPicShow = parseFloat(e.pageX);

            if(xPicShow > (wPicShow / 2)){
                mouseenterOffLeftRight = true;
            }else{
                mouseenterOffLeftRight = false;
            }

            var src = this.$slots.default()[0].props.src;
			this.$root.imgShow(src,mouseenterOffLeftRight)
		},

        mouseleaveFun(){
			this.$root.imgHide()
        }
    }
  }
  </script>
  
  <style  lang="less">
  .imgItem{
	display: inline;
  }
  </style>
  