import { createStore } from 'vuex'  
  
const store = createStore({  
  state: {  

    isMobile:false,
    user_times:0,

    user:0,
    token:0,
	port:1,

	rank:0,
	rankKey:0,
	rankBut:{},

	nav_scene_data:'',

	// 任务跳转到规格列表的携带的参数
	task_obj:0, // 任务数据
	task_id:0, // 任务id
	task_state:0, // 任务状态
	task_times_id:0, // 任务时间id
	task_times_obj:0, // 任务时间数据
	task_rule_title:'', // 任务规格中显示的标题名称
	task_times_title:'', // 任务时间中显示的标题名称

	// 优惠券
	coupon_title:'', // 优惠券中的标题名称
	coupon_id:0, // 优惠券任务id
	coupon_obj:0, // 优惠券任务对象

	// 城市报价
	area_offer_supply:{
		offer_id:0, // 报价任务id
		gong_id:0, // 供应商id
		is_type:'1', // 售卖类型
		type_id:'1', // 售卖类型
		state:1, // 状态
		gong_title:'', // 供应商名称
		batch:0 // 批次
	},

	area_offer_area:{
		offer_id:0, // 报价任务id
		t_id:'', // 任务id
		is_type:'1', // 售卖类型
		type_id:'1', // 售卖类型
		state:1, // 状态
		t_title:'', // 任务名称
		batch:0 // 批次
	},
	area_offer_task_id:0, // 报价标题
	area_offer_gong_id:'', // 报价标题 
	area_offer_task_is_type:0, // 报价标题
	area_offer_state:0, // 报价标题
	area_offer_area_title:'', // 报价标题
	area_offer_task_title:'', // 报价标题
	area_offer_task_name:'', // 报价标题

	// 供应商
	supply_granary_obj:0,
	supply_granary_in:[],
	supply_granary_id:0,
	supply_user_list:[],

	supply_offer_task_id:0, // 报价标题
	supply_offer_gong_id:'', // 报价标题
	supply_offer_task_tid:0, // 报价标题
	supply_offer_state:0, // 报价标题
	supply_offer_area_title:'', // 报价标题
	supply_offer_task_title:'', // 报价标题
	supply_offer_task_name:'', // 报价标题
	// ----------
    count: 0,
  },  
  mutations: {  
    // 修改信息
		setState(state,result) {
			state[result.key] = result.value
		},

		getState(state, option) {
			// 键名
			const key = option.key
			// 结果
			const value = option.value

			// 更新对象
			state[key] = value
		},
  }  
})

export default store
