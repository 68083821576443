<template>
	<el-button :type="type" @click="but">
        <slot></slot>
	</el-button>
</template>
  
<script>
export default {
	name: 'isRankBut',
	props: {
		type: {
			type: String,
			default: 'primary'
		},
	},

    emits:['clicks'],

	data() {

		return {

		}
	},

	created() {
	},

	methods: {

		but(){
			this.$emit('clicks', '');
		},

	}
}
</script>
  
<style  lang="less"></style>
  