<template>
  <div style="height: 100%;">
    <homeNav style="height: 100%;" port="admin">
      <router-view></router-view>
    </homeNav>
  </div>
</template>
  
<script>
	import homeNav from '@/components/coom/home/homenav.vue'

  export default {
    name: 'adminHome',

    components: {
			homeNav,
		},

    data() {
      return {
        isCollapse:0
      }
    },

    // 创建之前
    beforeCreate(){
    },

    // 创建完
    created() {
    },

    methods: {
      
    },
  }

</script>


<style lang="less">
  // 全局css

  
</style>

