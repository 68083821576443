<template>
  <div style="height: 100%;">
    <el-container style="height: 100%; border: 1px solid #eee">

      <template v-if="!isMobile">
          <el-aside v-if="navShowOff" width="200px" style="height: 100%;"></el-aside>
      </template>
      <template v-else>
          <div class="mubu" v-if="navShowOff" @click="navShowOff = !navShowOff"></div>
      </template>
      <el-aside v-if="navShowOff" width="200px" style="background-color: rgb(238, 241, 246);height: 100%;overflow: unset;position: fixed;top:0px;left:0px;z-index: 999;">

        <el-header class="df jcc aic header1" style="height:60px;">
          青穗网
          <span v-if="port == 'admin'">（主后台）</span>
          <span v-if="port == 'agency'">（代理端）</span>
          <span v-if="port == 'area'">（城市端）</span>
          <span v-if="port == 'granary'">（仓库端）</span>
          <span v-if="port == 'supply'">（供应商）</span>
        </el-header>

        <el-aside style="height: calc(100% - 100px); border: 1px solid #eee;overflow-y: auto;background:#fff;">

          <el-menu :collapse="isCollapse" default-active="2" class="el-menu-vertical-demo" bg-color="#f2f2f2"
            :unique-opened="true" v-if="user">
            <el-menu-item index="0" @click="url('/' + port + '/index/index')">
              <el-icon>
                <component :is="'Monitor'" />
              </el-icon>
              <span>首页</span>
            </el-menu-item>

            <template v-if="nav && nav[port]">
            <template v-for="(val1, key1) in nav[port]" :key="key1">
              <template v-if="val1.children.length == 0">
                <el-menu-item :index="key1 + ''" @click="url(val1.url,val1.scene)">{{ val1.title }}</el-menu-item>
              </template>
              <template v-else>

                <el-sub-menu :index="key1 + ''">

                  <template #title>
                    <el-icon v-if="val1.inc.length > 0">
                      <component :is="val1.inc" :color="val1.color" />
                    </el-icon>
                    <span>{{ val1.title }}</span>
                  </template>

                  <template v-for="(val2, key2) in val1.children" :key="key2">
                    <template v-if="val2.children.length == 0">
                      <el-menu-item :index="key1 + '-' + key2" @click="url(val2.url,val1.scene)">{{ val2.title }}</el-menu-item>
                    </template>
                    <template v-else>
                      <el-sub-menu :index="key1 + '-' + key2">
                        <template #title><span>{{ val2.title }}</span></template>
                        <template v-for="(val3, key3) in val2.children" :key="key3">
                          <el-menu-item :index="key1 + '-' + key2 + '-' + key3"
                            @click="url(val3.url,val1.scene)">{{ val3.title }}</el-menu-item>
                        </template>
                      </el-sub-menu>
                    </template>
                  </template>

                </el-sub-menu>

              </template>
            </template>
            </template>

          </el-menu>

        </el-aside>

        <el-header class="df jcc aic header2">
          <el-dropdown v-if="user" @command="handleCommand">
            <span class="el-dropdown-link" style="outline: unset;">
              {{ user.qsw_user.t_nickname }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="loginDel">退出登录</el-dropdown-item>
                <el-dropdown-item command="rankUp">更新权限</el-dropdown-item>

                <template v-for="(val, key) in user.port_list" :key="key">
                  <el-dropdown-item :command="'login:' + val.id + ':' + val.port">切换到：{{ val.name }}</el-dropdown-item>
                </template>

              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-header>

      </el-aside>

      <el-main :style="'position: relative;'+(isMobile ? 'padding: unset;' : '')">
        <div class="showNavPostion df jcc aic" @click="navShowOff = !navShowOff" :style="'left:'+(isMobile && navShowOff ? 200 : 0)+'px;'">
          <el-icon v-if="navShowOff"><ArrowLeftBold size="20" /></el-icon>
          <el-icon v-else><ArrowRightBold size="20" /></el-icon>
        </div>
        <slot></slot>
      </el-main>
    </el-container>
  </div>
</template>
    
<script>

export default {
  name: 'HomeItem',

  props: {
    port: {
      types: Number,
      default: 'admin'
    }
  },

  data() {

    var isMobile = this.$store.state.isMobile;
    var navShowOff = !isMobile;

    return {
      user: 0,
      nav: 0,
      isCollapse: false,
      formSubOff:false,
      isMobile:isMobile,
      navShowOff:navShowOff,
    }
  },

  // 创建之前
  beforeCreate() {
  },

  // 创建完
  created() {
    var that = this;
    that.user = that.$store.state.user
    that.nav = that.$store.state.nav
    that.util.loginIs(function () {
      that.user = that.$store.state.user
      that.nav = that.$store.state.nav
    }, true, true);

  },

  methods: {
    url(path,scene = '') {
      this.$store.state.nav_scene_data = scene
      this.$router.push({
        path: path
      })
    },

    handleCommand(val) {
      console.log('val', val)
      var that = this;

      if (val == 'loginDel') {
        that.util.logout()
      } else if (val == 'rankUp') {
        that.util.loginIs(function () {
          that.user = that.$store.state.user
          that.nav = that.$store.state.nav
        }, true, true);
      } else {
        val = val.split(':');
        if (val.length == 3) {
          if (val[0] == 'login') {
            that.ajax('/login/user/port',{
              port_id:val[1],
              login_port:val[2],
            },function(res){

              if(res.code == 200){
                var user = res.data;

                that.util.setLogin(user)

                that.util.alert({
                  cancel:false,
                  value:res.msg,
                  success:function(){
                    that.util.loginUrl();
                  }
                })
              }else{
                that.util.alert({
                  cancel:false,
                  value:res.msg,
                })
              }

            })
          }
        }
      }

    },

    ajax(url, data = {}, fun = '') {
      var that = this

      if (that.formSubOff) {
        return false;
      }
      that.formSubOff = true;
      data.port_type = that.port_type

      that.util.request({
        url: url,
        data: data,
        success(res) {
          that.formSubOff = false;
          fun ? fun(res) : '' ;
          console.log('nav_ok', res)
        },
        fail(res) {
          console.log('nav_fail', res)
        }
      })
    },

  },
}

</script>
  
  
<style lang="less">
// 全局css

#app {
  height: 100vh;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-container::-webkit-scrollbar {
  display: none
}

.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  height: 15px;
}

.el-table__fixed .el-table__body {
  padding-bottom: 15px;
}

.el-table__fixed-right .el-table__body {
  padding-bottom: 15px;
}

.header1,
.header2 {
  width: auto;
  height: 40px;
  padding: 0 10px;
  background: #00a65a;
  color: #fff;
  cursor: pointer;
}

.header2 {
  .el-dropdown-link {
    color: #fff;
    display: flex;
    align-items: center;
  }
}

.header1{
  span{
    font-size: 12px;
  }
}

.mubu{
  width: 100%;
  height: 100%;
  position: fixed;
  left:0px;
  bottom:0px;
  z-index: 998;
  background-color: rgba(0,0,0,0.5);
}

.showNavPostion{
  width:40px;
  height: 30px;
  background-color: rgba(0, 0, 0, .7);
  color: #fff;
  position: absolute;
  bottom:0px;
  z-index: 999;
}
</style>
  
  