<template>
    <div class="pageList" style="height: 100%;">
		<!-- 常规 -->
		<el-drawer v-model="show" :size="search.size" :direction="search.direction" title="搜索条件">
			<el-form ref="form" :model="dataObj" label-width="120px" label-position="right">
				<el-row :gutter="20">
					<!-- eq like dataTime_qujian data_qujian batch sum_max_min -->
					<template v-for="(val1,key1) in searchs" :key="key1">
						<el-col :span="6" v-if="val1.way == 'eq'">
							
							<el-form-item :label="val1.name">
								<el-select
									v-model="dataObj[key1]"
									:placeholder="val1.name"
									style="width: 100%"
									clearable
								>
									<el-option label="空" value="" />
									<el-option v-for="(val2,key2) in val1.data" :key="key2" :label="val2" :value="key2" />
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :span="6" v-else-if="val1.way == 'like'">
							<el-form-item :label="val1.name">
								<el-input v-if="val1.type == 'number'" type="number" v-model="dataObj[key1]" :placeholder="val1.name" />
								<el-input v-else v-model="dataObj[key1]" :placeholder="val1.name" />
							</el-form-item>
						</el-col>

						<el-col :span="6" v-else-if="val1.way == 'number'">
							<el-form-item :label="val1.name">
								<el-input type="number" v-model="dataObj[key1]" :placeholder="val1.name" />
							</el-form-item>
						</el-col>

						<el-col :span="12" v-else-if="val1.way == 'dataTime_qujian'">
							<el-form-item :label="val1.name">
								<el-date-picker
									v-model="dataObj[key1]"
									type="datetimerange"
									start-placeholder="开始日期时间"
									end-placeholder="结束日期时间"
									format="YYYY-MM-DD HH:mm:ss"
									date-format="YYYY-MM-DD"
									time-format="hh:mm:ss"
									value-format="YYYY-MM-DD HH:mm:ss"
									
								/>
							</el-form-item>
						</el-col>

						<el-col :span="6" v-else-if="val1.way == 'data_qujian'">
							<el-form-item :label="val1.name">
								<el-date-picker
									v-model="dataObj[key1]"
									type="daterange"
									start-placeholder="开始日期"
									end-placeholder="结束日期"
									date-format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
								/>
							</el-form-item>
						</el-col>
						
						<el-col :span="6" v-else-if="val1.way == 'batch'">
							<el-form-item :label="val1.name">
								<el-date-picker
									v-model="dataObj[key1]"
									type="date"
									placeholder="选择日期"
									date-format="YYYY-MM-DD"
									value-format="YYYY-MM-DD"
								/>
							</el-form-item>
						</el-col>

						<el-col :span="12" v-else-if="val1.way == 'sum_max_min'">

							<el-form-item :label="val1.name">
								<el-row>
									<el-col :span="10">
										<el-input-number v-model="dataObj[key1].min" placeholder="最小值为0则不限" style="width: 100%;"/>
									</el-col>
									<el-col :span="4" style="text-align: center;">
										--
									</el-col>
									<el-col :span="10">
										<el-input-number v-model="dataObj[key1].max" placeholder="最大值为0则不限" style="width: 100%;"/>
									</el-col>
								</el-row>
							</el-form-item>
						</el-col>

						<el-col :span="24" v-if="val1.trEnd == 1"></el-col>

					</template>
				</el-row>

				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item>
							<el-button type="primary" @click="onSubmit()">提交</el-button>
							<el-button @click="show = false">取消</el-button>
						</el-form-item>
					</el-col>
					<el-col :span="12" style="text-align: right;">
						<el-button type="primary" @click="onSubmit()">提交</el-button>
						<el-button @click="show = false">取消</el-button>
					</el-col>
				</el-row>

			</el-form>
        </el-drawer>

		<el-header class="df jcsb aic" :height="headerHeight">
			<div class="df jcfs aic">
				<slot name="topLeftBut">

				</slot>
			</div>
			<div class="df jcfs aic">
				<slot name="topConBut">

				</slot>
			</div>
			<div class="df jcfe aic">
				<slot name="topRightBut">

				</slot>
				<el-button type="primary" @click="searchShow()" v-if="searchOff">搜索</el-button>
			</div>
		</el-header>

        <slot></slot>

        <el-footer class="df jcsb aic" :height="footerHeight" v-if="pageOffs">
			
			<div class="df jcfs aic">
				<el-pagination
					layout="total, sizes, prev, pager, next, jumper"
					v-model:current-page="dataObj.page"
					v-model:page-size="dataObj.size"
					:page-sizes="sizes"
					:small="true"
					:total="pageSumCou"
					@update:pageSize="sizeChange"
					@update:currentPage="pageChange"
				/>

			</div>
			<div class="df jcfe aic">
				<slot name="bottomRightBut">

				</slot>
			</div>

		</el-footer>
    </div>
</template>
  
<script>
  export default {
    name: 'pageList',
    props: {
        tableData: {types:Array,default:() => ''},
        sizes:{types:Array,default:[30,60,100,200,300,400,500,600]},
        size:{types:Number,default:100},
        pageOff:{types:Boolean,default:true},
        url:{types:String,default:''},
        searchOff:{types:Boolean,default:true},
        createdOff:{types:Boolean,default:true},
		
		headerHeight:{
            types:String,
            default:'40px',
		},

		footerHeight:{
            types:String,
            default:'40px',
		},

		loadPageCou:{
            types:Boolean,
            default:true,
		},
		load:{
            types:Boolean,
            default:true,
		},
		pageNoIs:{
            types:Boolean,
            default:false,
		},
		
        datas:{ // 默认请求数据
            types:Object,
			default:() => ({})
        },
        search:{ // 搜索弹窗
            types:Object,
            default:{
                size:'auto',
                direction:'ttb',
            }
        }
    },

    emits:['update:datas','update:tableData','pageTableDataUpdate'],

	watch: {
		datas(newVal, oldVal) {
			this.setFormData();
			console.log(`${oldVal} 变 ${newVal}`);  
		}  
	},

    data(){

        return {
            pageOffs:this.pageOff, // 分页开关
            pageSumCou:0, // 总条数
            dataObj:{
                page:1,
                size:this.size,
            },

            show:false,
            searchs:{},
        }
    },

    created(){
		if(this.createdOff){
			this.setData(this.datas,this.load,this.loadPageCou,this.pageNoIs);
		}
    },

    methods:{

        searchShow(){
            this.show = true;
        },

		onSubmit(){
            this.show = false;
			this.setData({},this.load,this.loadPageCou,this.pageNoIs,false);
		},

		setLoadData(){
			this.setData(this.datas,true,this.loadPageCou,this.pageNoIs,this.searchOff);
		},

		setFormData(){
			this.setData(this.datas,this.load,this.loadPageCou,this.pageNoIs,this.searchOff);
		},

        /**
		 * 初始化数据获取条件参数
		 * @param {Object} dataObj 获取数据的条件 {is_hot:1} 
		 * @param {Boolean} load 调用此方法时是否加载数据 默认：true
		 * @param {Boolean} loadPageCou 使用先获取总条数 后 再获取数据列表 默认：false
		 * @param {Boolean} pageNoIs 是否分页 默认：false
		 * @param {Boolean} searchOff 是否更新搜索条件 默认：true
		 */
		setData(dataObj = {},load = true,loadPageCou = false,pageNoIss = false,searchOff = true){
			
			if (typeof dataObj.page !== 'undefined') {
				this.dataObj.page = dataObj.page;
			}

			if (typeof dataObj.size !== 'undefined') {
				this.dataObj.size = dataObj.size;
			}

			for(var k in dataObj){
				if(
					k != 'page'
					&& k != 'size'
				){
					this.dataObj[k] = dataObj[k];
				}
			}

			if(pageNoIss){
				this.dataObj.pageNoIs = 1
			}else{
				this.dataObj.pageNoIs = 0
			}

			if(load){
				if(loadPageCou){
					this.getPageCou(searchOff);
				}
				this.getData();
			}
		},

		// 获取数据列表
		getData(){
			var that = this

			that.util.request({
				url:that.url,
				data:that.dataObj,
				success(res){
					const data = res.data;
					console.log('查询数据列表',data)

                    that.$emit('update:tableData', data.data);
                    that.$emit('pageTableDataUpdate', res);
					
				},
				fail(res){ 
					console.log('page_fail',res)
				}
			})
		},

		// 获取总条数
		getPageCou(searchOff = true){
			var that = this

			var dataCanObj = that.util.jsonObj(that.dataObj);

			dataCanObj['pageCouIs'] = 1;
			dataCanObj['pageNoIs'] = 1;

			that.util.request({
				url:that.url,
				data:dataCanObj,
				load:false,
				success(res){
					const data = res.data;
					console.log('查询数据总条数',data)
					that.pageSumCou = parseInt(data.dataCou)

					// eq number like dataTime_qujian data_qujian batch sum_max_min
					if(searchOff){
						for(var k in data.search){
							if(data.search[k].way == 'sum_max_min'){
								that.dataObj[k] = {
									min:0,
									max:0,
								};
							}else{
								that.dataObj[k] = '';
							}
						}

						console.log('搜索条件更新',data.search)
						that.searchs = data.search;
					}

				},
				fail(res){ 
					console.log('pagel_cou',res)
				}
			})
		},

		// 每页显示条数改变时触发
		sizeChange(val){
			this.dataObj.size = val

			// 初始化数据获取条件参数
			this.getData();
		},

		// 当前页码改变时触发
		pageChange(val){
			this.dataObj.page = val
			// 获取数据列表
			this.getData();
		},
    }
  }
  </script>
  
  <style lang="less">
  </style>
  